<template>
  <v-col v-if="offices.length == 0" cols="12" class="text-center">
    <div class="col-12 col-md-8 mx-auto">
      <h3>Actualmente no contamos con covertura en tu zona</h3>
      <p>Contáctanos para ayudarte y atender tu caso de manera personal</p>
      <ul class="social-link">
        <li>
          <a href="https://www.facebook.com/Wapidu.oficial" target="_blank"
            ><v-icon>mdi-facebook</v-icon></a
          >
        </li>
        <li>
          <a href="https://wa.me/525549854728" target="_blank"><v-icon>mdi-whatsapp</v-icon></a>
        </li>
        <li>
          <a href="tel:525549854728" target="_blank"><v-icon>mdi-phone</v-icon></a>
        </li>
      </ul>
    </div>
  </v-col>
  <v-col v-else cols="12" class="text-center">
    <Form @submit="save">
      <v-row>
        <v-col>
          <v-breadcrumbs :items="breadcrumbs" class="ma-0 pa-0" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h1>{{ this.uuid ? 'Editar' : 'Agregar' }} orden:</h1>
        </v-col>

        <v-col cols="12" class="d-flex justify-center">
          <v-radio-group v-model="individualSelection" row :disabled="readOnly">
            <v-radio label="Cita para recolección" :value="false" />
            <v-radio label="Arma tu pedido" :value="true" />
          </v-radio-group>
        </v-col>

        <v-col v-if="cannot('customer')" cols="12" sm="6" lg="4">
          <SelectField
            v-model="form.customer_id"
            :disabled="!!this.uuid"
            :items="customers"
            item-text="full_name"
            item-value="uuid"
            label="Cliente *"
            prepend-icon="mdi-account"
            rules="required"
          />
        </v-col>

        <v-col v-if="cannot('customer')" cols="12" sm="6" lg="4">
          <SelectField
            v-model="form.employee_id"
            :items="employees"
            item-text="full_name"
            item-value="uuid"
            label="Empleado *"
            prepend-icon="mdi-account"
            rules="required"
          />
        </v-col>

        <v-col v-if="cannot('customer')" cols="12" sm="6" lg="4">
          <SelectField
            v-model="form.vehicle_id"
            :items="vehicles"
            item-text="name"
            item-value="uuid"
            label="Vehículo *"
            prepend-icon="mdi-car"
            rules="required"
          />
        </v-col>

        <v-col v-if="can('customer')" cols="12" sm="6" lg="4">
          <SelectField
            v-model="office"
            :items="offices"
            item-text="name"
            item-value="id"
            label="Sucursal *"
            prepend-icon="mdi-store"
            rules="required"
            return-object
            :disabled="readOnly"
          />
        </v-col>
        <v-col cols="12" sm="6" lg="4">
          <TextField
            v-model="form.date"
            label="Fecha *"
            type="date"
            prepend-icon="mdi-calendar"
            rules="required"
            :disabled="readOnly"
            :min="currentDate"
          />
        </v-col>

        <v-col cols="12" sm="6" lg="4">
          <SelectField
            v-model="pickupTime"
            :items="pickupTimeOptions"
            @change="updatePickupTimes"
            item-text="name"
            item-value="id"
            label="Horario Preferido"
            prepend-icon="mdi-store"
            rules="required"
            return-object
            :disabled="readOnly"
          />
        </v-col>

        <v-col class="d-none">
          <TextField
            v-model="form.time_start"
            label="Hora Inicio *"
            type="time"
            prepend-icon="mdi-clock-outline"
            rules="required|schedule"
            :disabled="readOnly"
          />

          <TextField
            v-model="form.time_end"
            label="Hora Fin *"
            type="time"
            prepend-icon="mdi-clock-outline"
            rules="required|schedule"
            :disabled="readOnly"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"> </v-col>
      </v-row>
      <v-row v-if="individualSelection">
        <ProductSelection
          ref="productSelection"
          :items="form.items"
          :office="office"
          :paid-pickup="order.paid_pickup"
          :read-only="readOnly"
        />
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <h4>La mejor opción si tienes dudas.</h4>
          <p>
            Se programará una cita y nuestro personal recibirá tu paquete de prendas y te dará el
            precio en el momento de la recolección.
          </p>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-text class="text-center">
              <b class="mr-1 text-h5">TOTAL: $ 35.00</b>
              <br />

              <small style="line-height: 1">
                Estos $35 pesos son a cuenta de tu nota.
                <br />
                En caso de cancelar la cita antes de 24 horas se tomarán para tu siguiente pedido.
                <br />
                En caso de hacer una visita en falso (no cancelar o cambiar la cita 24 hrs previo)
                se tomará como pena.
              </small>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col v-if="!can('customer')" cols="12" class="text-center">
          <v-btn color="primary" type="submit" :disabled="disableSubmit">
            <v-icon left>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-col>

        <v-col v-else cols="12" class="text-center">
          <v-btn color="success" :disabled="disableSubmit" @click="showPayment"> Pagar </v-btn>
        </v-col>
      </v-row>

      <Payment :show.sync="showPaymentModal" :amount="total" @save="save" />
    </Form>
  </v-col>
</template>

<script>
// Libraries
import moment from 'moment';

// API
import CustomerAPI from '@/api/customer';
import EmployeeAPI from '@/api/employee';
import OfficeAPI from '@/api/office';
import OrderAPI from '@/api/order';
import VehicleAPI from '@/api/vehicle';

// Components
import Payment from '@/components/payment/index.vue';
import ProductSelection from '@/components/order/ProductSelection.vue';

export default {
  name: 'OrderForm',

  props: {
    uuid: {
      type: String,
      default: null,
    },
  },

  components: {
    Payment,
    ProductSelection,
  },

  data: () => ({
    form: {
      customer_id: null,
      date: null,
      employee_id: null,
      items: [],
      time_end: null,
      time_start: null,
      vehicle_id: null,
    },
    autoFill: false,
    discount: {
      code: null,
    },
    currentDate: moment().format('YYYY-MM-DD'),
    customers: [],
    employees: [],
    individualSelection: false,
    office: {},
    order: {},
    offices: [],
    pickupTime: {},
    pickupTimeOptions: [
      { name: 'Mañana (9am a 12am)', id: '1' },
      { name: 'Medio día (12am a 3pm)', id: '2' },
      { name: 'Tarde (3pm a 6pm)', id: '3' },
      { name: 'Noche (6pm a 9pm)', id: '4' },
    ],
    postalCode: null,
    readOnly: false,
    vehicles: [],

    // Payment modal
    showPaymentModal: false,
    total: 0,
  }),

  computed: {
    breadcrumbs() {
      const items = [
        {
          text: 'Órdenes',
          disabled: false,
          exact: true,
          to: { name: 'order-list' },
        },
      ];

      if (this.uuid) {
        const name = this.form.number || '';
        items.push(
          {
            text: name.toUpperCase(),
            disabled: false,
            exact: true,
            to: { name: 'order-detail', params: { uuid: this.uuid } },
          },
          {
            text: 'Editar',
            disabled: true,
          },
        );
      } else {
        items.push({
          text: 'Agregar',
          disabled: true,
        });
      }

      return items;
    },

    disableSubmit() {
      const collectionDateTime = this.form.date && this.form.time_end && this.form.time_start;
      const validateOffice = this.can('customer') ? !!this.office.uuid : true;

      return this.individualSelection
        ? !collectionDateTime || !this.form.items.length || !validateOffice
        : !collectionDateTime || !validateOffice;
    },
  },

  watch: {
    async individualSelection() {
      this.form.items = [];

      if (this.order.uuid) {
        this.form.items = this.getOrderItems(this.order);
      }
    },
  },

  async created() {
    this.api = new OrderAPI();
    this.customerAPI = new CustomerAPI();
    this.employeeAPI = new EmployeeAPI();
    this.officeAPI = new OfficeAPI();
    this.vehicleAPI = new VehicleAPI();

    if (this.$route.query.autoFill !== undefined) {
      this.autoFill = true;
    }
    if (this.can('customer')) {
      this.postalCode = this.$store.getters.profile.address?.postal_code;
      if (!this.postalCode) {
        this.$router.push({
          name: 'account',
          query: {
            redirect: this.$route.path,
          },
        });
        this.$store.dispatch('notification/notifyError', 'Por favor completa tu perfil.');
        return;
      }
      await this.getAvailableOffices();
      if (this.autoFill) {
        this.individualSelection = true;
      }
    } else {
      await this.getCustomers();
      await this.getEmployees();
      await this.getVehicles();
    }

    if (this.uuid) {
      await this.detail();
    }
  },

  methods: {
    updatePickupTimes() {
      console.log('pickupTime');
      console.log(this.pickupTime.id);
      console.log();
      switch (Math.floor(this.pickupTime.id)) {
        // 9 a 12
        case 1:
          this.form.time_start = '09:00';
          this.form.time_end = '11:59';
          break;
        // De 12 a 3
        case 2:
          this.form.time_start = '12:00';
          this.form.time_end = '14:59';
          break;
        // De 3 a 6
        case 3:
          this.form.time_start = '15:00';
          this.form.time_end = '17:59';
          break;
        // De 6 a 9
        case 4:
          this.form.time_start = '18:00';
          this.form.time_end = '19:59';
          break;
        // Todo el día
        default:
          this.form.time_start = '09:00';
          this.form.time_end = '19:59';
          break;
      }
    },
    getOrderItems(order) {
      return order.products.map((product) => ({
        quantity: product.quantity,
        product: product.product,
      }));
    },

    async save() {
      this.$store.commit('showLoader');
      try {
        this.form.discount = this.individualSelection
          ? this.$refs.productSelection.getDiscount()
          : null;

        if (this.office.uuid) {
          this.form.office_uuid = this.office.uuid;
        }

        if (this.can('customer')) {
          this.form.customer_id = null;
          this.form.employee_id = null;

          if (this.individualSelection) {
            this.form.paid = true;
          } else {
            this.form.paid_pickup = true;
          }
        }

        if (this.uuid) {
          await this.api.update(this.uuid, this.form);
          this.$router.push({ name: 'order-detail', params: { uuid: this.uuid } });
        } else {
          this.form.pickup = !this.individualSelection;
          await this.api.create(this.form);
          this.$router.push({ name: 'order-list' });
        }

        this.$store.dispatch('notification/notifySuccess');
      } catch (error) {
        console.log(error);
        this.$store.dispatch('notification/notifyError', 'No se pudieron guardar los datos.');
      }
      this.$store.commit('hideLoader');
    },

    async detail() {
      this.$store.commit('showLoader');
      try {
        const order = await this.api.detail(this.uuid);
        this.form = {
          number: order.number,
          items: this.getOrderItems(order),
          customer_id: order.customer.uuid,
          date: order.assignation.date,
          time_end: order.assignation.time_end,
          time_start: order.assignation.time_start,
          vehicle_id: order.assignation.vehicle?.uuid,
          employee_id: order.assignation.operator?.uuid,
        };
        this.office = order.office;
        this.individualSelection = !!order.products.length;
        this.readOnly = this.can('customer') && !order.paid;
        this.order = order;
      } catch (error) {
        console.log(error);
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },

    async getCustomers() {
      this.$store.commit('showLoader');
      try {
        const res = await this.customerAPI.list();
        this.customers = res.results;
      } catch (error) {
        console.log(error);
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },

    async getEmployees() {
      this.$store.commit('showLoader');
      try {
        const res = await this.employeeAPI.list({ form: true });
        this.employees = res.results;
      } catch (error) {
        console.log(error);
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },

    async getVehicles() {
      this.$store.commit('showLoader');
      try {
        const res = await this.vehicleAPI.list();
        this.vehicles = res.results;
      } catch (error) {
        console.log(error);
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },

    async getAvailableOffices() {
      this.$store.commit('showLoader');
      try {
        this.offices = await this.officeAPI.availableOffices({
          postal_code: this.postalCode,
          // postal_code: '07969',
        });
        if (this.offices.length > 1) {
          const [firstOffice] = this.offices;
          // this.office = this.offices[0];
          this.office = firstOffice;
        } // else if (this.offices.length === 0) { }
      } catch (error) {
        console.log(error);
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },

    showPayment() {
      if (this.individualSelection) {
        this.total = this.$refs.productSelection.getTotal();
      } else {
        this.total = this.order.paid_pickup ? 0 : 35;
      }
      this.showPaymentModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
ul.social-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
ul.social-link li {
  list-style: none;
  display: flex;
  flex-direction: justify-center;
  align-items: center;
  padding: 2rem;
}
</style>
